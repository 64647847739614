import { document, window } from '../globals';
import { isObject } from '../typeguards';
import { localStorage } from '../util/storage';

interface ChatbotState {
    state: {
        minimized: boolean;
        visible: boolean;
    };
}

type SetConversationVariableMetadata = {
    key: string;
    value: string;
};

export const isSetConversationVariableMetadata = (
    setConversationVariableMetadata: unknown,
): setConversationVariableMetadata is SetConversationVariableMetadata => (
    isObject(setConversationVariableMetadata) && (
        typeof setConversationVariableMetadata?.key === 'string'
        && typeof setConversationVariableMetadata?.value === 'string'
    )
);

export default class GiftyChat {
    private static SKIPPED_INTERACTION_MESSAGE = 'Notice: System skipped interaction';
    private static hasBeenLoaded = false;

    private static loading = false;

    private static readonly WIDGET_URL = 'https://webchat.digitalcx.com/webchat.js';

    private static readonly BUTTON_SELECTOR = '.cm-button-js';

    private static readonly DATA_CX_ID_SELECTOR = 'data-cxID';
    private static readonly DATA_CX_WEB_STORE_CONTEXT_VARIABLE_SELECTOR = 'data-cxWebStoreContextVariable';
    private static readonly DATA_CX_WEB_STORE_CONVERSATION_VARIABLE_SELECTOR = 'data-cxWebStoreConversationVariable';

    private static getCxId(button: HTMLButtonElement): string | null {
        return button.getAttribute(this.DATA_CX_ID_SELECTOR);
    }

    private static getCxWebStoreContextVariable(button: HTMLButtonElement): string | null {
        return button.getAttribute(this.DATA_CX_WEB_STORE_CONTEXT_VARIABLE_SELECTOR);
    }

    private static getCxWebStoreConversationVariable(button: HTMLButtonElement): string | null {
        return button.getAttribute(this.DATA_CX_WEB_STORE_CONVERSATION_VARIABLE_SELECTOR);
    }

    private static getButtonElement(): HTMLButtonElement | null | undefined {
        return document?.querySelector(this.BUTTON_SELECTOR);
    }

    public static bindListeners(): void {
        const button = this.getButtonElement();
        if (!(button instanceof HTMLButtonElement)) {
            return;
        }
        button.addEventListener('click', () => {
            if (!this.hasBeenLoaded) {
                this.loadScript(button);
                return;
            }

            const cxId = this.getCxId(button);
            if (!cxId) {
                return;
            }

            this.toggleChat(cxId);
        });
    }

    private static openChat(cxId: string) {
        const chatBotInstance = window?.cmwc?.get(cxId);
        if (chatBotInstance && isObject(chatBotInstance)) {
            chatBotInstance.open();
        }
    }

    private static onAnswer(cxId: string) {
        const chatBotInstance = window?.cmwc?.get(cxId);
        if ((chatBotInstance && isObject(chatBotInstance))) {
            chatBotInstance.onAnswer((event) => {
                if (event.detail.chat.metadata.data.outputAdditions?.setConversationVariable) {
                    const obj = JSON.parse(event.detail.chat.metadata.data.outputAdditions.setConversationVariable) as unknown;

                    if (isSetConversationVariableMetadata(obj)) {
                        chatBotInstance.addConversationVariables({ [obj.key]: obj.value });
                    }
                }

                if (event.detail.chat.metadata.data.outputAdditions?.skipInteraction === 'true') {
                    chatBotInstance.caic.askHidden(this.SKIPPED_INTERACTION_MESSAGE);
                }
            });
        }
    }

    private static showChat(cxId: string) {
        const chatBotInstance = window?.cmwc?.get(cxId);
        if (chatBotInstance && isObject(chatBotInstance)) {
            chatBotInstance.show();
        }
    }

    private static hideChat(cxId: string) {
        const chatBotInstance = window?.cmwc?.get(cxId);
        if (chatBotInstance && isObject(chatBotInstance)) {
            chatBotInstance.hide();
        }
    }

    private static getState(cxId: string): ChatbotState | null {
        const appItem = localStorage.getItem(`${cxId}.app`);

        return appItem ? JSON.parse(appItem) as ChatbotState : null;
    }

    private static toggleChat(cxId: string) {
        const chatState = this.getState(cxId)?.state;

        if (!chatState) {
            return;
        }

        // Gifty doesn't properly set the state of 'visible' after closing
        // In which case we have to open the chat rather than show it
        if (chatState.visible && chatState.minimized) {
            this.openChat(cxId);

            return;
        }

        if (!chatState.visible) {
            this.showChat(cxId);

            return;
        }

        this.hideChat(cxId);
    }

    private static addInitialWebStoreContext(cxId: string): void {
        const chatBotInstance = window?.cmwc?.get(cxId);
        const button = this.getButtonElement();
        if (button && chatBotInstance && isObject(chatBotInstance)) {
            const webStoreContextVariable = this.getCxWebStoreContextVariable(button);

            if (webStoreContextVariable) {
                chatBotInstance.addContext({ webStore: webStoreContextVariable });
            }
        }
    }

    private static addInitialWebStoreConversationVariable(cxId: string): void {
        const chatBotInstance = window?.cmwc?.get(cxId);
        const button = this.getButtonElement();
        if (button && chatBotInstance && isObject(chatBotInstance)) {
            const webStoreConversationVariable = this.getCxWebStoreConversationVariable(button);

            if (webStoreConversationVariable) {
                chatBotInstance.addConversationVariables({ webStore: webStoreConversationVariable });
            }
        }
    }

    private static addInitialPageTypeContextVariable(cxId: string): void {
        const chatBotInstance = window?.cmwc?.get(cxId);
        if (chatBotInstance && isObject(chatBotInstance)) {
            const pageType = window?.pageType.replaceAll(' ', '_');

            if (pageType) {
                chatBotInstance.addContext({ pageType });
            }
        }
    }

    private static loadScript(button: HTMLButtonElement): void {
        const cxId = this.getCxId(button);

        if (!cxId || !window || !document || this.loading) {
            return;
        }

        this.toggleLoad(true);

        const scriptElement = document.createElement('script');
        scriptElement.type = 'module';
        scriptElement.onload = () => {
            this.hasBeenLoaded = true;
            this.toggleLoad(false);

            window?.cmwc?.add(cxId, () => {
                if (window?.siteMetadata?.language) {
                    window?.cmwc?.get(cxId)?.setLanguage(window.siteMetadata.language.toLowerCase());
                }

                this.addInitialWebStoreContext(cxId);
                this.addInitialPageTypeContextVariable(cxId);
                this.addInitialWebStoreConversationVariable(cxId);

                this.openChat(cxId);
            }).install();

            // If there is already a state in localStorage, the callbackFunction from .add() is not being called, so we open chat here instead
            if (this.getState(cxId)) {
                this.openChat(cxId);
            }
            this.onAnswer(cxId);
        };

        scriptElement.src = this.WIDGET_URL;

        document.body.appendChild(scriptElement);
    }

    private static toggleLoad(state: boolean): void {
        this.loading = state;
    }
}
